import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

const db = getFirestore();

export const updateUserPreferences = async (
  userId: string,
  preferenceType: string,
  preferences: any
) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, { [preferenceType]: preferences });
};

export const getUserPreferences = async (
  userId: string,
  preferenceType: string
) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  const data = userDoc.data();
  return data?.[preferenceType] ?? [];
};
