import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Box,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../../images/Goldi-Animation.svg';
import { PrimaryActionButton } from '../components/PrimaryActionButton';
import { useEffect, useState } from 'react';
import enabledLogo from '../../../../images/sunburst.enabled.png';
import Confetti from 'react-confetti';
import AuthService from 'services/auth.service';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from 'firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { updateUserPreferences } from 'services/preference.service';

export const UserSignUp = () => {
  const [user] = useAuthState(auth);

  const { enabledAllergies, enabledDiets } = useDietaryPreferences();
  const theme = useTheme();
  const { restaurantId } = useParams();
  const navigate = useNavigate();

  const [runConfetti, setRunConfetti] = useState(true);

  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }

    const timer = setTimeout(() => {
      setRunConfetti(false);
    }, 5000); // Stop after 5 seconds

    return () => clearTimeout(timer); // Clean up the timeout on unmount
  }, [user]);

  const orderHeader2 = {
    py: 2,
    top: 0,
    width: '100%',
    zIndex: 999,
    textAlign: 'center',
    // color: theme.palette.grey[0],
    // backgroundColor: "#fe8154;",
    borderBottom: '1.5px solid #F0BD4E',
  };

  const goToLink = async () => {
    setIsRedirecting(true);
    if (restaurantId) {
      // AuthService.logoutWithoutRedirect('/explore/' + restaurantId + '/menu');
      window.location.href = '/explore/' + restaurantId + '/menu';
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async () => {
    try {
      setError('');
      setIsLoading(true);
      const user = await AuthService.signup(email, password, name, phoneNumber);
      updateUserPreferences(user.uid, 'selectedAllergies', enabledAllergies);
      updateUserPreferences(user.uid, 'selectedDiets', enabledDiets);
    } catch (error) {
      if ((error as any).code === 'auth/email-already-in-use') {
        setError('Email already in use');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isRedirecting) {
    return <></>;
  }

  return (
    <Stack sx={{ backgroundColor: theme.palette.grey[0] }}>
      <Stack sx={orderHeader2} direction='row' alignContent='center'>
        <ArrowBackIosNewIcon
          onClick={goToLink}
          sx={{
            zIndex: 100,
            cursor: 'pointer',
            color: '#1E423A',
            height: '20px',
            marginTop: 1.5,
            marginLeft: 2,
            marginRight: -5.5,
          }}
        />
        <Box flex={1} />
        <img
          src={logo}
          alt='Goldi'
          style={{
            height: '42px', // Adjust size as needed
            objectFit: 'contain',
            padding: 4,
            cursor: 'pointer', // Indicates clickability
          }}
          onClick={() =>
            window.open(
              'https://www.ordergoldi.com/',
              '_blank',
              'noopener,noreferrer'
            )
          }
        />
        <Box flex={1} />
      </Stack>
      {user ? (
        <Stack
          height='100vh'
          justifyContent='center'
          alignItems='center'
          gap={2}
        >
          <img src={enabledLogo} width='120px' />
          <Typography variant='h3' textAlign='center'>
            Thank you for signing up!
          </Typography>
          <PrimaryActionButton
            title='Back to Menu'
            onClick={goToLink}
            disabled={isLoading}
          />
          <Confetti recycle={runConfetti} />
        </Stack>
      ) : (
        <Stack p={4} gap={3}>
          <Stack px={-4}>
            <Typography variant='body2' textAlign='center'>
              Enjoy your Goldi experience?
            </Typography>
            <Typography variant='body2' textAlign='center'>
              Create an account for faster service
            </Typography>
            <Typography variant='body2' textAlign='center'>
              in the future.
            </Typography>
          </Stack>
          {error && (
            <Typography variant='body2' textAlign='center' color='error'>
              {error}
            </Typography>
          )}
          <TextField
            label='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant='outlined'
          />
          <TextField
            label='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant='outlined'
            type='email'
          />
          <TextField
            label='Phone Number'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            variant='outlined'
            type='number'
          />
          <TextField
            label='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box ml={2} alignSelf='center' width={300}>
            <PrimaryActionButton
              title={'Submit'}
              onClick={handleSubmit}
              disabled={isLoading}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
