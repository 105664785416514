import { Box, Container, Divider, Typography } from '@mui/material';
import { useApi } from 'providers/ApiProvider';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryActionButton } from '../../ExploreRestaurant/components/PrimaryActionButton';
import { useCart } from '../context/CartContext';
import { UserOrder } from '../model/cart';
import {
  checkValidEmail,
  checkValidPhone,
  formatCentsToDollars,
} from '../util/util';
import AddSpecialInstructions from './AddSpecialInstructions';
import { UserOrderMenuItem } from './UserOrderMenuItem';
import { auth } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

interface Props {
  order: UserOrder;
  isEditable: boolean;
  setInstruction: (str: string) => void;
  instruction: string;
  orderType: string;
}

export const UserOrderItem = ({
  order,
  isEditable,
  instruction,
  setInstruction,
  orderType,
}: Props) => {
  const { items, total } = order;

  const [user] = useAuthState(auth);

  const {
    publicRestaurant,
    cart,
    updateQuantity,
    setCartTotal,
    removeFromCart,
    clearCart,
    saveOrder,
  } = useCart();
  const { restaurantId } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const [orderCustomizations, setOrderCustomizations] = useState<any>({});
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const userId = '1';
  const orderFooterProps = {
    textAlign: 'center',
    p: 1,
    px: 2,
    mb: 5,
  };
  const appStoreFormProps = {
    mb: 20,
  };

  useEffect(() => {
    getOrderCustomizationsHandler();
  }, [cart]);

  const saveInstruction = (val: string) => {
    setInstruction(val);
  };

  const getOrderCustomizationsHandler = async () => {
    const res = await api.getOrderCustomizations(order);
    setOrderCustomizations(res);
  };

  const goBackToRestaurant = async () => {
    await clearCart();
    window.location.href = `/explore/${restaurantId}/menu`;
  };

  const goToOrder = async (id: number) => {
    setEmailError('');
    if (userEmail && !checkValidEmail(userEmail)) {
      setEmailError('Please enter a valid email!');
      return false;
    }
    setPhoneError('');
    if (userPhone && !checkValidPhone(userPhone)) {
      setEmailError('Please enter a valid phone number!');
      return false;
    }

    if (restaurantId) {
      const catObj = {
        restaurant: { id: publicRestaurant.id },
        cart: {
          serviceFee: 0,
          deliveryFee: 0,
          tipFee: cart.tipFee,
          taxFee: cart.taxFee,
          orders: cart.orders,
          subtotal: cart.subtotal,
          grandTotal: cart.grandTotal,
        },
        notes: instruction,
        email: userEmail,
        phoneNumber: userPhone,
        orderType: orderType,
        addressString: publicRestaurant.address,
      };
      await api.pushGuestOrders(catObj);
      await saveOrder(catObj);
      await clearCart();
      // navigate('/explore/'.concat(restaurantId, `/order/${id}`));
      navigate(`/explore/${restaurantId}/signup`);
    }
  };

  const boxProperties = {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    padding: 2,
    textAlign: 'center',
    display: 'flex-box',
    flexDirection: 'column',
    left: 0,
  };

  const updateQuantityHandler = (obj: any) => {
    updateQuantity(obj);
  };

  const handleOnMenuItemDelete = (obj: any) => {
    removeFromCart(obj);
  };
  return (
    <Box mt={7}>
      <Container>
        <Typography variant='body1'>Place Your Order:</Typography>
        <Box mb={2}>
          <Typography variant='subtitle1' fontSize={12}>
            Pro tip: Your order is powered by Goldi&apos;s algorithm - follow
            these instructions to get your customized dish!
          </Typography>
        </Box>
        <Divider />
        {items.map((item, index) => (
          <UserOrderMenuItem
            orderCustomizations={orderCustomizations[index]}
            userId={userId}
            handleOnMenuItemDelete={(obj: any) => handleOnMenuItemDelete(obj)}
            updateQuantity={(obj: any) => updateQuantityHandler(obj)}
            key={`row-${item.menuItem.id}-${index}`}
            item={item}
            isEditable={isEditable}
            index={index}
          />
        ))}
      </Container>
      {/* <Container>
        <AddSpecialInstructions
          instruction={instruction}
          saveInstruction={(str: string) => saveInstruction(str)}
          isEditable={isEditable}
        />
      </Container> */}
      <Box sx={orderFooterProps}>
        <Typography variant='subtitle2' sx={{ fontSize: 20 }}>
          Subtotal {formatCentsToDollars(total)}
        </Typography>
      </Box>
      {isEditable && items.length > 0 && (
        <Box sx={boxProperties}>
          <Box mb={1}>
            <PrimaryActionButton
              title={'All Done !'}
              onClick={() => {
                if (user) {
                  goBackToRestaurant();
                } else {
                  goToOrder(1);
                }
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
