import { useApi } from 'providers/ApiProvider';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import {
  generateAllergiesQuery,
  generateDietsQuery,
} from 'Utils/helpers/queryGenerators';
import { DietPreference } from 'interfaces/DietPreferenceInterface';
import { setUserProperties } from 'firebase/analytics';
import { analytics } from 'firebase';
import { auth } from 'firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  getUserPreferences,
  updateUserPreferences,
} from 'services/preference.service';

const DietaryPreferenceContext = createContext({} as any);

interface Props {
  children: ReactNode;
}

export const DietaryContextProvider = ({ children }: Props) => {
  const api = useApi();

  const [user, isUserLoading] = useAuthState(auth);

  const setLocal = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const getLocal = (key: string) => {
    const data = localStorage.getItem(key);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    } else {
      return [];
    }
  };

  const [selectedAllergies, setSelectedAllergies] = useState<string[]>(
    getLocal('selectedAllergies')
  );
  const [selectedDiets, setSelectedDiets] = useState<string[]>(
    getLocal('selectedDiets')
  );
  const [enabledAllergies, setEnabledAllergies] = useState<string[]>(
    getLocal('enabledAllergies')
  );
  const [enabledDiets, setEnabledDiets] = useState<string[]>(
    getLocal('enabledDiets')
  );
  const [username, setUsername] = useState(getLocal('username'));

  const [allergyKeyToName, setAllergyKeyToName] = useState(
    {} as { [key: string]: string }
  );

  const [dietKeyToName, setDietKeyToName] = useState(
    {} as { [key: string]: string }
  );

  const {
    status: _allergiesStatus,
    error: _allergiesError,
    data: allergies,
    isLoading: _isLoadingAllergies,
  } = useQuery(generateAllergiesQuery(), async () => {
    return api.getAllergies();
  });

  const {
    status: _dietsStatus,
    error: _dietsError,
    data: diets,
    isLoading: _isLoadingDiets,
  } = useQuery(generateDietsQuery(), async () => {
    const data = await api.getDiets();
    return data.filter(
      (x: any) => !['slow_carb', 'clean_program'].includes(x.key)
    );
  });

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    if (user) {
      getUserPreferences(user.uid, 'selectedAllergies').then((data) => {
        setSelectedAllergies(data);
      });

      getUserPreferences(user.uid, 'selectedDiets').then((data) => {
        setSelectedDiets(data);
      });
    } else {
      setSelectedAllergies(getLocal('selectedAllergies'));
      setSelectedDiets(getLocal('selectedDiets'));
    }
  }, [user, isUserLoading]);

  useEffect(() => {
    setAllergyKeyToName(
      ((allergies ?? []) as DietPreference[]).reduce((obj, allergy) => {
        obj[allergy.key] = allergy.name;
        return obj;
      }, {} as { [key: string]: string })
    );
  }, [allergies]);

  useEffect(() => {
    setDietKeyToName(
      ((diets ?? []) as DietPreference[]).reduce((obj, diet) => {
        obj[diet.key] = diet.name;
        return obj;
      }, {} as { [key: string]: string })
    );
  }, [diets]);

  const toggleSelectedAllergy = (key: string) => {
    if (!selectedAllergies) {
      return;
    }

    if (selectedAllergies.includes(key)) {
      const data = selectedAllergies.filter((k) => k !== key);
      setLocal('selectedAllergies', JSON.stringify(data));
      setSelectedAllergies(data);
    } else {
      const data = [...selectedAllergies, key];
      setLocal('selectedAllergies', JSON.stringify(data));
      setSelectedAllergies(data);
    }

    toggleEnabledAllergy(key);
  };

  const setUsernameHandler = (val: string) => {
    setLocal('username', val);
    setUsername(val);
  };

  useEffect(() => {
    if (selectedAllergies) {
      setUserProperties(analytics, {
        selectedAllergies,
      });
    }
    if (user) {
      updateUserPreferences(user.uid, 'selectedAllergies', selectedAllergies);
    }
  }, [selectedAllergies]);

  useEffect(() => {
    if (selectedDiets) {
      setUserProperties(analytics, {
        selectedDiets,
      });
    }

    if (user) {
      updateUserPreferences(user.uid, 'selectedDiets', selectedDiets);
    }
  }, [selectedDiets]);

  const toggleSelectedDiet = (key: string) => {
    if (!selectedDiets) {
      return;
    }

    if (selectedDiets.includes(key)) {
      const data = selectedDiets.filter((k) => k !== key);
      setLocal('selectedDiets', JSON.stringify(data));
      setSelectedDiets(data);
    } else {
      const data = [...selectedDiets, key];
      setLocal('selectedDiets', JSON.stringify(data));
      setSelectedDiets(data);
    }
    toggleEnabledDiet(key);
  };

  const toggleEnabledAllergy = (key: string) => {
    setEnabledAllergies((allergies) => {
      const newAllergies = [...allergies];
      const index = newAllergies.indexOf(key);

      if (index === -1) {
        newAllergies.push(key);
      } else {
        newAllergies.splice(index, 1);
      }
      setLocal('enabledAllergies', JSON.stringify(newAllergies));
      return newAllergies;
    });
  };

  const toggleEnabledDiet = (key: string) => {
    setEnabledDiets((diets) => {
      const newDiets = [...diets];
      const index = newDiets.indexOf(key);

      if (index === -1) {
        newDiets.push(key);
      } else {
        newDiets.splice(index, 1);
      }
      setLocal('enabledDiets', JSON.stringify(newDiets));
      return newDiets;
    });
  };

  const resetAllergies = () => {
    setSelectedAllergies([]);
    setLocal('selectedAllergies', JSON.stringify([]));
  };

  const resetDiets = () => {
    setSelectedDiets([]);
    setLocal('selectedDiets', JSON.stringify([]));
  };

  const clearAllergies = () => {
    const allergies = getLocal('selectedAllergies');
    setEnabledAllergies(allergies);
    setLocal('enabledAllergies', JSON.stringify(allergies));
  };

  const clearDiets = () => {
    const diets = getLocal('selectedDiets');
    setEnabledDiets(diets);
    setLocal('enabledDiets', JSON.stringify(diets));
  };

  return (
    <DietaryPreferenceContext.Provider
      value={{
        allergies,
        diets,
        username,
        selectedAllergies: selectedAllergies ?? [],
        selectedDiets: selectedDiets ?? [],
        enabledAllergies,
        enabledDiets,
        allergyKeyToName,
        dietKeyToName,
        toggleSelectedAllergy,
        toggleSelectedDiet,
        setUsernameHandler,
        toggleEnabledAllergy,
        toggleEnabledDiet,
        clearAllergies,
        clearDiets,
        resetAllergies,
        resetDiets,
      }}
    >
      {children}
    </DietaryPreferenceContext.Provider>
  );
};

interface ContextProps {
  allergies: DietPreference[];
  diets: DietPreference[];
  username: string;
  selectedAllergies: string[];
  selectedDiets: string[];
  enabledAllergies: string[];
  enabledDiets: string[];
  allergyKeyToName: { [key: string]: string };
  dietKeyToName: { [key: string]: string };
  toggleSelectedAllergy: (key: string) => void;
  toggleSelectedDiet: (key: string) => void;
  toggleEnabledAllergy: (key: string) => void;
  toggleEnabledDiet: (key: string) => void;
  setUsernameHandler: (key: string) => void;
  clearAllergies: () => void;
  clearDiets: () => void;
  resetAllergies: () => void;
  resetDiets: () => void;
}

export const useDietaryPreferences = () =>
  useContext<ContextProps>(DietaryPreferenceContext);
